import { deleteCookie, getCookie, setCookie } from "cookies-next"
export const userKey = "nature.user"

export function parseToken(token) {
  if (!token) {
    return null
  }
  return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString())
}

export function setUser(user) {
  setCookie(userKey, user, { sameSite: "none", secure: true })
}

export function deleteUser() {
  deleteCookie(userKey)
}

export function getUserToken() {
  const cookie = getCookie(userKey)
  let auth

  if (cookie) {
    auth = JSON.parse(cookie)
  }

  return auth?.credentials?.token
}

export function getUser() {
  const cookie = getCookie(userKey)
  const auth = JSON.parse(cookie)

  return auth?.user
}

export function maybeValidUser() {
  const token = getUserToken()
  const tokenData = parseToken(token)
  if (tokenData?.exp) {
    if (Math.floor(new Date().getTime() / 1000) < tokenData.exp) {
      return true
    }
  }
  return false
}
